import * as Sentry from "@sentry/remix";
import { Result } from "result-type-ts";
import * as Exception from "../domain/exception";

/**
 * Custom error classes that we use to try to group uncaught errors
 */
const uncaughtErrorsTypes = [
  Exception.UncaughtNetworkFetchError,
  Exception.UncaughtLoadFailedError,
  Exception.CookieConsentScriptError,
  Exception.MutinyScriptError,
];

export const groupUncaughtErrors = (
  errorEvent: Sentry.ErrorEvent,
  hint: Sentry.EventHint,
): Sentry.ErrorEvent | null => {
  // If we already handled this error, send it as-is
  if (
    hint.originalException &&
    hint.originalException instanceof Exception.ConnectApiError
  ) {
    errorEvent.fingerprint = [hint.originalException.name];
    return errorEvent;
  }

  /**
   * Try to match this error event to one of the custom error types we've defined
   */
  const error: Error | null = Result.tryCatch(() => {
    return uncaughtErrorsTypes.reduce<Error | null>(
      (foundError, ErrorType) => foundError ?? ErrorType.tryFrom(errorEvent),
      null,
    );
  })
    .mapError((e) => e as Error)
    .toUnion();

  if (error) {
    Sentry.captureException(error, hint);
    /**
     * Returning null means Sentry won't report the original error
     */
    return null;
  }

  return errorEvent;
};
