import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { replayIntegration } from "@sentry/remix";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { groupUncaughtErrors } from "../lib/groupUncaughtErrors";

if (window.CONFIG?.SENTRY_ENV && window.CONFIG?.SENTRY_DSN) {
  Sentry.init({
    dsn: window.CONFIG.SENTRY_DSN,
    environment: window.CONFIG.SENTRY_ENV,
    tracesSampleRate: Number(window.CONFIG?.SENTRY_SAMPLE_RATE),
    profilesSampleRate: Number(window.CONFIG?.SENTRY_SAMPLE_RATE),
    autoInstrumentRemix: true,
    replaysSessionSampleRate: Number(window.CONFIG?.SENTRY_SAMPLE_RATE),

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      // prod
      /https:\/\/(?:connect-api\.)?vendr\.com/,
      // staging, preview & dev
      /https:\/\/(?:connect-api\.)?vendr-stg\.com/,
      /https:\/\/(?:connect-api\.)?vendr-dev\.com/,
      "connect-api.vendr.com",
      "connect-api.vendr-stg.com",
      "connect-api.vendr-dev.com",
      "vendr-dev.com",
    ],
    integrations: (defaultIntegrations) => [
      ...defaultIntegrations,
      replayIntegration({
        maskAllText: window.CONFIG?.SENTRY_MASK_REPLAY_CONTENT,
        blockAllMedia: window.CONFIG?.SENTRY_MASK_REPLAY_CONTENT,
        networkDetailAllowUrls: window.CONFIG?.SENTRY_MASK_REPLAY_CONTENT
          ? []
          : [window.location.origin],
      }),
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
    ignoreErrors: [
      // It comes from Intercom script
      // https://vendrinc.sentry.io/issues/5576016423/events/523fe49dda8342e1a814d4f9e337b507/?project=4507249967431680
      "evaluating 'parent.document'",
      // Example: https://vendrinc.sentry.io/issues/5562361319/?project=4507249967431680
      "Failed to load https://www.googletagmanager.com/gtag",
      // Example: https://vendrinc.sentry.io/issues/5646597774/events/ce6b9e4421ca4d4c8587c9ffe52ce6e2/?project=4507249967431680
      "window.UET is not a constructor",
    ],
    /**
     * Try to catch errors that are thrown by code outside of the Sellerportal source code,
     * and group them into know error types.
     */
    beforeSend: groupUncaughtErrors,
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
